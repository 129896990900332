// stylelint-disable
@font-face {
  font-family: 'Telia Sans';
  src: url("../fonts/teliasans/TeliaSans-Regular.otf") format("otf"),
      url("../fonts/teliasans/TeliaSans-Regular.woff") format("woff"),
      url("../fonts/teliasans/TeliaSans-Regular.ttf") format("truetype")
}

@font-face {
  font-family: 'Telia Sans Bold';
  src: url("../fonts/teliasans/TeliaSans-Bold.otf") format("otf"),
      url("../fonts/teliasans/TeliaSans-Bold.woff") format("woff"),
      url("../fonts/teliasans/TeliaSans-Bold.ttf") format("truetype")
}
